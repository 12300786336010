.thumbnail{
  cursor: pointer;
  display: inline-flex;
  padding: 0.5em;
  width: 20%;
  height: 3rem;
  border: 1px solid transparent;
  transition: "all 0.4s ease";

  @media (min-width: 900px) {
    display: flex;
    width: 100%;
    padding: 10%;
    margin-bottom: 5%;
  }

  &:hover{
    border-color: #D8D8D8;
    transition: "all 0.4s ease";
  }

  &.active{
    border-color: #333333;

    &:hover{
      border-color: #333333;
    }
  }

  span{
    flex: 1;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 2px;
  }
}