body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  /* background-color: #fafafa !important; */
  background-color: #f4f4f4 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}

.main-nav{
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.main-nav-wrapper{
  align-items: center;
  background-color: white;
  box-shadow: 1px 0.1rem 0.1rem rgb(207 209 212 / 60%), 0 0 0.1rem 0 rgb(151 140 254 / 30%);
  display: flex;
  height: 3.25rem;
}

@media only screen and (min-width: 900px) {
  .main-nav-wrapper{
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10000;
  }
}